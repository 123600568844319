module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sudo Academy","short_name":"Sudo Academy","start_url":".","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/sudolabs.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef20aee932b6bd1efaafeeea3f620ff8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"facebookPixel":{"pixelId":"1055566574920414","cookieName":"gatsby-gdpr-facebook-pixel"},"hotjar":{"hjid":"2189358","hjsv":"6","cookieName":"gatsby-gdpr-hotjar"},"environments":["production","development"]},
    }]
